import { RequestHandler, IRequestHandler } from '../lib/RequestHandler'
import { store } from '../store'
import { expenses } from '../store/expenses/slice'
import { GetAllExpensesResponse, DownloadExpensesDataBody } from '../ts/interfaces/request'
import { ExpenseStatus, DownloadExpensesType } from '../ts/interfaces/expenses'
import FileDownload from 'js-file-download'
import moment from 'moment'
import { uiSlice } from '../store/ui/slice'
export const ExpensesService = (props?: IRequestHandler) => ({
  requestHandler: RequestHandler(props || {}),

  async getExpenseStatus(id: string | number): Promise<ExpenseStatus> {
    try {
      const response = await this.requestHandler.get<{ data: ExpenseStatus }>(`expenses/${id}`)
      return response.data.data
    } catch (error) {
      throw error
    }
  },

  async getAllExpenses({ start_date, end_date, user_id, tax_information_id }: { start_date: string; end_date: string; user_id?: string | null; tax_information_id?: string | null }) {
    try {
      store.dispatch(uiSlice.actions.setIsFetching(true))

      let params: Record<string, string> = {
        start_date,
        end_date
      }
      if (user_id) params = { ...params, user_id: user_id }
      if (tax_information_id) params = { ...params, tax_information_id }

      const urlSearchParams = new URLSearchParams(params)

      const response = await RequestHandler({
        ...props,
        useLoader: false
      }).get<GetAllExpensesResponse>(`expenses?${urlSearchParams}`)
      store.dispatch(expenses.actions.setExpensesList(response.data.data))
      store.dispatch(uiSlice.actions.setIsFetching(false))
    } catch (error) {
      throw error
    }
  },

  async downloadExpensesData(bodyParams: DownloadExpensesDataBody) {
    try {
      const urlSearchParams = new URLSearchParams({
        download_type: bodyParams.download_type,
        start_date: bodyParams.start_date.toISOString(),
        end_date: bodyParams.end_date.toISOString()
      })

      const response = await this.requestHandler.post(
        `expenses/download?${urlSearchParams}`,
        {},
        {
          timeout: 60000,
          responseType: 'blob'
        }
      )

      const type = bodyParams.download_type == DownloadExpensesType.ZIP_PDF_XML ? 'zip' : bodyParams.download_type.toLowerCase()

      FileDownload(response.data, `gastos_${moment(bodyParams.start_date).format('DD-MM-YYYY')}_${moment(bodyParams.end_date).format('DD-MM-YYYY')}.${type}`)
    } catch (error) {
      throw error
    }
  }
})
