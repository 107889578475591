import { TaxInformation, ZReceipt, InvoiceStatus } from './invoices'
import { User } from './interfaces'

export interface Expense {
  id: number
  user_id: number
  category: string
  date: Date
  amount: number
  name: string
  receipt_id?: number
  receipt?: ZReceipt
  image?: string
  created_at: Date
  updated_at: Date
  deleted_at?: Date
}

export interface ExpenseStatus {
  receipt: InvoiceStatus
  expense: Expense
  user: User
  tax_information: TaxInformation
}

export enum DownloadExpensesType {
  CSV = 'CSV',
  ZIP_PDF_XML = 'ZIP_PDF_XML'
}
