import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Receipt } from '../../ts/interfaces/invoices'
import { TaxInformation, InvoiceStatus } from '../../ts/interfaces/invoices'

interface State {
  taxInformation: TaxInformation[]
  receipts: InvoiceStatus[]
  count: number
  limit: number
  total: number
  active: boolean
}

export const initialState: State = {
  taxInformation: [],
  receipts: [],
  count: 0,
  limit: 0,
  total: 0,
  active: false
}

export const invoices = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    setInvoicesState: (state, action: PayloadAction<Partial<State>>) => {
      return { ...state, ...action.payload }
    },
    addTaxInformationToList: (state, action: PayloadAction<TaxInformation>) => {
      return { ...state, taxInformation: [...state.taxInformation, action.payload] }
    },
    setTaxInformationList: (state, action: PayloadAction<TaxInformation[]>) => {
      return { ...state, taxInformation: action.payload }
    },
    setReceiptsList: (state, action: PayloadAction<InvoiceStatus[]>) => {
      return { ...state, receipts: action.payload }
    },
    clearInvoicesState: () => {
      return initialState
    }
  }
})

export const { addTaxInformationToList, setTaxInformationList, setInvoicesState, clearInvoicesState, setReceiptsList } = invoices.actions

export default invoices.reducer
