'use client'
import React from 'react'
import { OnboardingProgress } from '../components/ui/OnboardingProgress'
import { ServiceUseProgress } from '../components/ui/ServiceUseProgress'
import { SendInvoiceBanner } from '../components/ui/SendInvoiceBanner'
import { PageLayout } from '../components/Layout/PageLayout'
import { useAppSelector } from '../store/hooks'
import { Flex } from '@chakra-ui/react'
import { Text } from '../components/fragments/Text'

const HomePage = () => {
  const subscription = useAppSelector((state) => state.subscription)

  return (
    <PageLayout>
      <ServiceUseProgress mb='1rem' />
      <OnboardingProgress mb='1.5rem' />
      {subscription.payment && subscription.payment?.type !== 'FREE_TRIAL' && (
        <Flex
          flexDir='column'
          width='100%'
          alignItems='center'
        >
          <Text>Escanea el QR para ir a WhatsApp desde tu celular</Text>
          <SendInvoiceBanner />
        </Flex>
      )}
    </PageLayout>
  )
}

export { HomePage }
