import { Flatten, User } from './interfaces'

export const TaxRegimes = {
  '601': 'General de Ley Personas Morales',
  '603': 'Personas Morales con Fines no Lucrativos',
  '624': 'Coordinados',
  '622': 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras',
  '623': 'Opcional para Grupos de Sociedades',
  '625': 'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas',
  '626': 'Régimen Simplificado de Confianza',
  '605': 'Sueldos y Salarios e Ingresos Asimilados a Salarios',
  '606': 'Arrendamiento',
  '607': 'Régimen de Enajenación o Adquisición de Bienes',
  '608': 'Demás ingresos',
  '610': 'Residentes en el Extranjero sin Establecimiento Permanente en México',
  '611': 'Ingresos por Dividendos (socios y accionistas)',
  '612': 'Personas Físicas con Actividades Empresariales y Profesionales',
  '614': 'Ingresos por intereses',
  '615': 'Régimen de los ingresos por obtención de premios',
  '616': 'Sin obligaciones fiscales',
  '621': 'Incorporación Fiscal'
}
export enum PaymentMethod {
  PM01 = 'Efectivo',
  PM02 = 'Cheque nominativo',
  PM03 = 'Transferencia electrónica de fondos',
  PM04 = 'Tarjeta de crédito',
  PM05 = 'Monedero electrónico',
  PM06 = 'Dinero electrónico',
  PM08 = 'Vales de despensa',
  PM12 = 'Dación en pago',
  PM13 = 'Pago por subrogación',
  PM14 = 'Pago por consignación',
  PM15 = 'Condonación',
  PM17 = 'Compensación',
  PM23 = 'Novación',
  PM24 = 'Confusión',
  PM25 = 'Remisión de deuda',
  PM26 = 'Prescripción o caducidad',
  PM27 = 'A satisfacción del acreedor',
  PM28 = 'Tarjeta de débito',
  PM29 = 'Tarjeta de servicios',
  PM30 = 'Aplicación de anticipos',
  PM99 = 'Por definir'
}
export const PAYMENT_METHOD_VALUES = [
  { label: 'Efectivo', value: 'PM01' },
  { label: 'Cheque nominativo', value: 'PM02' },
  { label: 'Transferencia electrónica de fondos', value: 'PM03' },
  { label: 'Tarjeta de crédito', value: 'PM04' },
  { label: 'Monedero electrónico', value: 'PM05' },
  { label: 'Dinero electrónico', value: 'PM06' },
  { label: 'Vales de despensa', value: 'PM08' },
  { label: 'Dación en pago', value: 'PM12' },
  { label: 'Pago por subrogación', value: 'PM13' },
  { label: 'Pago por consignación', value: 'PM14' },
  { label: 'Condonación', value: 'PM15' },
  { label: 'Compensación', value: 'PM17' },
  { label: 'Novación', value: 'PM23' },
  { label: 'Confusión', value: 'PM24' },
  { label: 'Remisión de deuda', value: 'PM25' },
  { label: 'Prescripción o caducidad', value: 'PM26' },
  { label: 'A satisfacción del acreedor', value: 'PM27' },
  { label: 'Tarjeta de débito', value: 'PM28' },
  { label: 'Tarjeta de servicios', value: 'PM29' },
  { label: 'Aplicación de anticipos', value: 'PM30' },
  { label: 'Por definir', value: 'PM99' }
]

export enum ReceiptStatus {
  not_receipt = 'No aplica',
  processing = 'Procesando',
  pending_files = 'pending_files',
  error = 'Error',
  pending = 'En proceso',
  done = 'Listo'
}

export enum CFDI {
  G01 = 'Adquisición de mercancías',
  G02 = 'Devoluciones, descuentos o bonificaciones',
  G03 = 'Gastos en general',
  I01 = 'Construcciones',
  I02 = 'Mobiliario y equipo de oficina por inversiones',
  I03 = 'Equipo de transporte',
  I04 = 'Equipo de computo y accesorios',
  I05 = 'Dados, troqueles, moldes, matrices y herramental',
  I06 = 'Comunicaciones telefónicas',
  I07 = 'Comunicaciones satelitales',
  I08 = 'Otra maquinaria y equipo',
  D01 = 'Honorarios médicos, dentales y gastos hospitalarios',
  D02 = 'Gastos médicos por incapacidad o discapacidad',
  D03 = 'Gastos funerales',
  D04 = 'Donativos',
  D05 = 'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)',
  D06 = 'Aportaciones voluntarias al SAR',
  D07 = 'Primas por seguros de gastos médicos',
  D08 = 'Gastos de transportación escolar obligatoria',
  D09 = 'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones',
  D10 = 'Pagos por servicios educativos (colegiaturas)',
  S01 = 'Sin efectos fiscales',
  CP01 = 'Pagos',
  CN01 = 'Nómina'
}

export const CFDI_VALUES = [
  { label: 'Adquisición de mercancías', value: 'G01' },
  { label: 'Devoluciones, descuentos o bonificaciones', value: 'G02' },
  { label: 'Gastos en general', value: 'G03' },
  { label: 'Construcciones', value: 'I01' },
  { label: 'Mobiliario y equipo de oficina por inversiones', value: 'I02' },
  { label: 'Equipo de transporte', value: 'I03' },
  { label: 'Equipo de computo y accesorios', value: 'I04' },
  { label: 'Dados, troqueles, moldes, matrices y herramental', value: 'I05' },
  { label: 'Comunicaciones telefónicas', value: 'I06' },
  { label: 'Comunicaciones satelitales', value: 'I07' },
  { label: 'Otra maquinaria y equipo', value: 'I08' },
  { label: 'Honorarios médicos, dentales y gastos hospitalarios', value: 'D01' },
  { label: 'Gastos médicos por incapacidad o discapacidad', value: 'D02' },
  { label: 'Gastos funerales', value: 'D03' },
  { label: 'Donativos', value: 'D04' },
  { label: 'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)', value: 'D05' },
  { label: 'Aportaciones voluntarias al SAR', value: 'D06' },
  { label: 'Primas por seguros de gastos médicos', value: 'D07' },
  { label: 'Gastos de transportación escolar obligatoria', value: 'D08' },
  { label: 'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones', value: 'D09' },
  { label: 'Pagos por servicios educativos (colegiaturas)', value: 'D10' },
  { label: 'Sin efectos fiscales', value: 'S01' },
  { label: 'Pagos', value: 'CP01' },
  { label: 'Nómina', value: 'CN01' }
]

export enum ErrorCode {
  RECEIPT_EXPIRED = 'receipt_expired',
  UNSUPPORTED_STORE = 'unsupported_store',
  RECEIPT_ILLEGIBLE = 'receipt_illegible',
  INCORRECT_FISCAL_DATA = 'incorrect_fiscal_data',
  INVOICED = 'invoiced',
  INVOICED_WITH_EMAIL = 'invoiced_with_email',
  WEBSITE_ERROR = 'website_error',
  UNABLE_TO_CONTACT_STORE = 'unable_to_contact_store',
  UNKNOWN_ERROR = 'unknown_error',
  ILLEGAL_RECEIPT = 'illegal_receipt',
  LIMIT_REACHED = 'limit_reached',
  INVOICING_NOT_SUPPORTED = 'invoicing_not_supported',
  RECEIPT_TRUNCATED = 'receipt_truncated',
  INVOICING_AT_STORE_ONLY = 'invoicing_at_store_only',
  SAME_DAY_INVOICING_REQUIRED = 'same_day_invoicing_required',
  CARD_VOUCHER_INSTEAD_OF_RECEIPT = 'card_voucher_instead_of_receipt',
  INVOICE_INSTEAD_OF_RECEIPT = 'invoice_instead_of_receipt',
  VAT_PAYMENT_REQUIRED_FOR_INVOICING = 'vat_payment_required_for_invoicing',
  AUTOMATIC_INVOICING_NOT_SUPPORTED = 'automatic_invoicing_not_supported'
}

export const TRANSLATED_ERROR_CODE: Record<string, string> = {
  receipt_expired: 'La fecha de compra es muy antigua y ya no es posible facturarla',
  unsupported_store: 'En estos momentos no es posible facturar compras en esta tienda ',
  receipt_illegible: 'La imagen del recibo no es legible, por favor toma una nueva foto para que podamos procesarlo ',
  incorrect_fiscal_data: 'Los datos fiscales no son correctos, por favor contáctanos a hola@zummafinancial.com para actualizar los datos fiscales ',
  invoiced: 'Parece que el recibo ya fue facturado ',
  invoiced_with_email: 'Tu factura ya fue generada y automáticamente enviada a tu email. 🙌',
  website_error: 'El portal del comercio ha reportado errores en repetidas ocasiones. ',
  unable_to_contact_store: 'Contactamos al comercio varias veces sin recibir respuesta. ',
  illegal_receipt: 'La imagen no corresponde a un recibo.',
  unknown_error: 'Parece que hubo un error inesperado ',
  invoicing_not_supported: 'En estos momentos el comercio no genera facturas.',
  limit_reached: 'Has alcanzado el límite de tickets permitidos con tu plan actual.',
  receipt_truncated: 'El recibo proporcionado está cortado donde viene la información de facturación.',
  invoicing_at_store_only: 'Este recibo solo puede ser facturado en la tienda física donde se realizó la compra.',
  same_day_invoicing_required: 'La facturación debe realizarse el mismo día de la compra.',
  card_voucher_instead_of_receipt: 'La imagen proporcionada es un comprobante de tarjeta, no un recibo válido para facturación.',
  invoice_instead_of_receipt: 'La imagen proporcionada es una factura en lugar de un recibo o ticket para facturación.',
  vat_payment_required_for_invoicing: 'El comercio exige que se pague el IVA para proceder con la facturación.',
  automatic_invoicing_not_supported: 'Este recibo no es compatible con el sistema de facturación automática.'
}

export interface ZReceipt {
  receipt_id: string
  id: number
  created_at: Date
  updated_at: Date
}
export interface Receipt {
  receipt_id: string
  tax_information_id: string
  amount: number
  store: string
  store_id: string
  cfdi: CFDI
  receipt_date: Date
  receipt_img: string
  passenger_names: string
  payment_method: PaymentMethod
  invoice_pdf_url: string
  invoice_xml_url: string
}
export interface Address {
  external_number: string
  internal_number: string
  country: string
  state: string // entidad federativa (estado)
  city: string // ciudad o municipio
  district: string // localidad
  neighborhood: string // colonia
  street: string // calle de domicilio
}

export interface ZTaxInformation {
  id: number
  tax_information_id: string
  created_at: Date
  updated_at: Date
  deleted_at?: Date
}
export interface TaxInformation extends ZTaxInformation {
  address: Address
  first_name: string
  middle_name: string
  surname: string
  last_name: string
  tax_id: string
  tax_certificate: string | null
  tax_regime: number | null
  postal_code: string
  business_name: string
}

export interface InvoiceStatus {
  data: Receipt
  status: keyof typeof ReceiptStatus
  error?: {
    code: ErrorCode
  }
  user: User
  tax_information: TaxInformation
}

export interface CompleteReceiptData extends Receipt {
  user?: User
  tax_information: TaxInformation
}

export interface TaxInformationForm extends Omit<Flatten<TaxInformation>, 'created_at' | 'updated_at' | 'country'> {}

export function getTaxInformationName(taxInformation: TaxInformation): string {
  const { first_name, last_name, middle_name, surname } = taxInformation
  if (taxInformation.tax_id.length >= 13) return `${first_name} ${middle_name} ${surname} ${last_name}`
  else return taxInformation.business_name
}
