import React from 'react'
import { TextField } from '../components/fields'
import { Button } from '../components/fragments/Button'
import { Title } from '../components/fragments/Title'
import { AbsoluteCenter, Flex, useToast, Text, Link as ChakraLink } from '@chakra-ui/react'
import * as Yup from 'yup'
import { useForm } from '../hooks/useForm'
import { AuthService } from '../services/Auth'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { signIn } from '../store/user/slice'
import { routerObj } from '../components/routing/Router'
import { colors } from '../utils/colors'
import { padding, rounded } from '../utils/ui'
//@ts-ignore
import ZummaBannerLogo from '../assets/svg/ZummaBannerLogo.svg'
import { ReactSVG } from 'react-svg'
import { PhoneField } from '../components/fields/PhoneFIeld/PhoneField'

const SignupSchema = Yup.object().shape({
  password: Yup.string().min(6, 'Too Short!').max(50, 'Too Long!').required('Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Passwords must match'),
  email: Yup.string().email('Invalid email').required('Required'),
  phone_number: Yup.string().min(6).required('Invalid phone number')
})

const SignUp = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
      phone_number: ''
    },
    validationSchema: SignupSchema,
    onSubmit: async (values) => {
      try {
        form.handleSetTouchedForm()
        const response = await AuthService().signUp({
          email: values.email.trim(),
          password: values.password.trim(),
          phone_number: values.phone_number.trim()
        })
        dispatch(
          signIn({
            id: response.data.id,
            email: response.data.email,
            token: response.session.access_token,
            refresh_token: response.session.refresh_token,
            phone_numbers: response.data.phone_numbers,
            role: response.data.role,
            name: response.data.name || ''
          })
        )
      } catch (error) {
        const axiosError = error as AxiosError
        //@ts-ignore
        const messageError = axiosError.response?.data.message as string
        console.log({ error, messageError })
        const messages: Record<string, string> = {
          'A user with this phone number already exists.': 'Número de WhatsApp inválido',
          'User already registered': 'Email inválido'
        }
        toast({
          title: messages[messageError] ? messages[messageError] : `Hubo un error: ${messageError}`,
          status: 'error',
          duration: 8000,
          isClosable: true
        })
      }
    }
  })

  return (
    <AbsoluteCenter
      width={{
        base: 'full',
        md: 'max-content'
      }}
      axis='both'
      bg='white'
      py={padding.container.py}
      px={padding.container.px}
      rounded={rounded.container}
    >
      <Flex
        justifyContent='center'
        mb='1rem'
      >
        <ReactSVG src={ZummaBannerLogo} />
      </Flex>
      <form
        className='flex flex-col items-center gap-[0.8rem] pb-[1rem]'
        onSubmit={(e) => {
          e.preventDefault()
          form.handleSubmit()
        }}
      >
        <Title>Registro</Title>
        <TextField
          isReadOnly={form.isSubmitting}
          isRequired={true}
          label='Correo Electrónico'
          name='email'
          id='email'
          type='TEXT'
          placeholder='ejemplo@zummafinancial.com'
          isDisabled={false}
          value={form.values.email}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          isInvalid={form.touched.email && form.errors.email ? true : false}
        />

        <PhoneField
          isReadOnly={form.isSubmitting}
          isRequired={true}
          label='Número de WhatsApp'
          name='phone_number'
          id='phone_number'
          placeholder='+'
          isDisabled={false}
          value={form.values.phone_number}
          onChange={(phone) => form.setFieldValue('phone_number', phone)}
          onBlur={form.handleBlur}
          isInvalid={form.touched.phone_number && form.errors.phone_number ? true : false}
        />

        <TextField
          isReadOnly={form.isSubmitting}
          isRequired={true}
          label='Contraseña'
          name='password'
          id='password'
          type='PASSWORD'
          isDisabled={false}
          value={form.values.password}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          isInvalid={form.touched.password && form.errors.password ? true : false}
        />

        <TextField
          isReadOnly={form.isSubmitting}
          isRequired={true}
          label='Introduzca de nuevo su contraseña'
          name='confirmPassword'
          id='confirmPassword'
          type='PASSWORD'
          isDisabled={false}
          value={form.values.confirmPassword}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          isInvalid={form.touched.confirmPassword && form.errors.confirmPassword ? true : false}
        />

        <Button
          isLoading={form.isSubmitting}
          mt={4}
          type='submit'
          id='submitFormButton'
        >
          Registrarme {'>>'}
        </Button>
        <Button
          isLoading={form.isSubmitting}
          borderStyle='dashed'
          bg='transparent'
          borderColor={colors.text[4]}
          textColor={colors.text[2]}
          _hover={{
            borderColor: colors.text[1]
          }}
          _active={{
            bg: 'transparent'
          }}
          onClick={() => navigate(routerObj.signIn.path)}
        >
          {'<<'} Iniciar sesión
        </Button>
      </form>

      <Flex
        flexDir='column'
        alignItems='center'
      >
        <Text
          mt='1rem'
          gap='.55rem'
          fontWeight={600}
          textColor={colors.tertiary[1]}
        >
          <ChakraLink
            target='_blank'
            href={process.env.REACT_APP_HELP_URL}
          >
            ¿Necesitas ayuda? Contáctanos
          </ChakraLink>
        </Text>
        <Text
          mt='1rem'
          gap='.55rem'
          fontWeight={600}
          textColor={colors.tertiary[1]}
        >
          <ChakraLink
            target='_blank'
            href='https://www.youtube.com/watch?v=0U2mHw9bDYU'
          >
            ¿Quieres conocer más? Ve este video
          </ChakraLink>
        </Text>
      </Flex>
    </AbsoluteCenter>
  )
}

export { SignUp }
