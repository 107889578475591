import { Flex } from '@chakra-ui/react'
import React from 'react'
import { DateRangePicker, DateValue } from '../fields/DateRangePicker/DateRangePicker'
import moment from 'moment'
import { ExpensesService } from '../../services/Expenses'
import { SelectField } from '../fields'
import { UserService } from '../../services/User'
import { setIsFetching } from '../../store/ui/slice'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../store/hooks'
import { getTaxInformationName } from '../../ts/interfaces/invoices'
import { useSearchParams } from 'react-router-dom'

interface IExpensesFilterProps {
  toggleIsLoading: {
    on: () => void
    off: () => void
    toggle: () => void
  }
}
const ExpensesFilter = React.memo(({ toggleIsLoading }: IExpensesFilterProps) => {
  const dispatch = useDispatch()
  const taxInformationList = useAppSelector((state) => state.invoices.taxInformation)
  const userList = useAppSelector((state) => state.organization.users)
  const [searchParams, setSearchParams] = useSearchParams()

  const [dateValue, setDateValue] = React.useState<DateValue>([moment().startOf('month').toDate(), moment().toDate()])

  const handleRetrieveData = async () => {
    if (dateValue[0] && dateValue[1] && moment(dateValue[0]).isValid() && moment(dateValue[1]).isValid()) {
      const searchFilter: { start_date: string; end_date: string; user_id?: string | null; tax_information_id?: string | null } = {
        start_date: moment(dateValue[0]).toISOString(),
        end_date: moment(dateValue[1]).toISOString(),
        user_id: searchParams.get('user_id') || null,
        tax_information_id: searchParams.get('tax_information_id') || null
      }

      toggleIsLoading.on()
      await ExpensesService().getAllExpenses(searchFilter)
      toggleIsLoading.off()
    }
  }

  React.useEffect(() => {
    handleRetrieveData()
  }, [dateValue, searchParams])

  React.useEffect(() => {
    ;(async () => {
      dispatch(setIsFetching(true))
      await UserService({ useLoader: false }).getAllUsers()
      dispatch(setIsFetching(false))

      if (!searchParams.get('start_date') && !searchParams.get('end_date')) {
        searchParams.set('start_date', (dateValue[0] as Date).toISOString())
        searchParams.set('end_date', (dateValue[1] as Date).toISOString())
        setSearchParams(searchParams)
      } else {
        setDateValue([moment(searchParams.get('start_date')).toDate(), moment(searchParams.get('end_date')).toDate()])
      }
    })()
  }, [])

  return (
    <Flex
      alignItems='center'
      justifyContent={{
        base: 'center',
        md: 'start'
      }}
      columnGap='1rem'
      flexDir='row'
      flexWrap='wrap'
      width='100%'
    >
      <DateRangePicker
        label='Filtrar por fecha'
        value={dateValue}
        onChange={(value) => {
          searchParams.set('start_date', moment(value[0]).toISOString())
          searchParams.set('end_date', moment(value[1]).toISOString())
          setSearchParams(searchParams)
          setDateValue(value)
        }}
        id='FilterExpenseDatePickerInput'
      />
      <SelectField
        id='FilterExpenseTaxInformationSelect'
        label='Filtrar por razón social'
        value={searchParams.get('tax_information_id')}
        options={taxInformationList.map((taxInformation) => ({
          label: `${getTaxInformationName(taxInformation)} - ${taxInformation.tax_id}`,
          value: taxInformation.tax_information_id
        }))}
        onChange={(_, value) => {
          if (value) searchParams.set('tax_information_id', value as string)
          else searchParams.delete('tax_information_id')
          setSearchParams(searchParams)
        }}
      />
      <SelectField
        id='FilterExpenseUserSelect'
        label='Filtrar por usuario'
        value={searchParams.get('user_id') ? parseInt(searchParams.get('user_id') as string) : null}
        options={userList.map((user) => ({
          value: user.id,
          label: user.name || user.email
        }))}
        onChange={(_, value) => {
          if (value) searchParams.set('user_id', value as string)
          else searchParams.delete('user_id')
          setSearchParams(searchParams)
        }}
      />
    </Flex>
  )
})

export { ExpensesFilter }
