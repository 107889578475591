'use client'
import React from 'react'
import { PageLayout } from '../components/Layout/PageLayout'
import { Badge, Flex, Icon, Menu, MenuButton, MenuItem, MenuList, Stack, Tag, Text, useMediaQuery, Button, useBoolean } from '@chakra-ui/react'
import { Title } from '../components/fragments/Title'
import { createColumnHelper } from '@tanstack/react-table'
import { DataTable } from '../components/fragments/Table'
import { useAppSelector } from '../store/hooks'
import { Receipt, ReceiptStatus } from '../ts/interfaces/invoices'
import { formatCurrencyAmount } from '../utils/ui'
import { MdDownload, MdFolderZip, MdPictureAsPdf } from 'react-icons/md'
import moment from 'moment'
import { colors, receiptStatusColor } from '../utils/colors'
import { useNavigate } from 'react-router-dom'
import { routerObj } from '../components/routing/Router'
import { IoEye } from 'react-icons/io5'
import { DownloadExpensesModal } from '../components/ui/DownloadExpensesModal'
import { ExpensesFilter } from '../components/ui/ExpensesFilter'

interface TableData extends Partial<Receipt> {
  actions?: string
  tax_identification?: string
  user_name?: string
  status: keyof typeof ReceiptStatus
  category: string
  expense_id: number
  date: Date
}

const receiptTableColumnHelper = createColumnHelper<TableData>()

const ReceiptsDashboardPage = () => {
  const expenses = useAppSelector((state) => state.expenses.expenses)
  const navigate = useNavigate()
  const [isLargeScreen] = useMediaQuery('(min-width: 1024px)')
  const [isLoading, toggleIsLoading] = useBoolean(false)

  const tableData = React.useMemo(() => {
    return expenses
      .reduce((acc: TableData[], item) => {
        let result: TableData = {
          category: item.expense.category,
          user_name: item?.user?.name || item?.user?.email,
          amount: item.expense.amount,
          status: 'not_receipt',
          expense_id: item.expense.id,
          store: item.expense.name,
          date: item.expense.date
        }

        if (item.tax_information && item.receipt) {
          const { business_name, first_name, surname, last_name, middle_name } = item.tax_information

          const tax_identification = item.tax_information.tax_id.length === 13 ? `${first_name} ${surname}` : business_name

          result = {
            ...result,
            ...item.receipt.data,
            status: item?.receipt.status,
            tax_identification
          }
        }

        acc.push(result)

        return acc
      }, [])
      .sort((a, b) => (moment(a.date) > moment(b.date) ? -1 : 1))
  }, [expenses])

  const receiptColumns = React.useMemo(
    () => [
      receiptTableColumnHelper.accessor('status', {
        cell: (info) => (
          //@ts-ignore
          <Badge bg={receiptStatusColor[info.getValue()]}>
            {/* @ts-ignore */}
            {ReceiptStatus[info.getValue()]}
          </Badge>
        ),
        header: 'Estatus Factura'
      }),
      receiptTableColumnHelper.accessor('category', {
        cell: (info) => <Badge>{info.getValue()}</Badge>,
        header: 'Categoría'
      }),
      receiptTableColumnHelper.accessor('tax_identification', {
        cell: (info) => info.getValue(),
        header: 'Razón Social'
      }),
      receiptTableColumnHelper.accessor('user_name', {
        cell: (info) => info.getValue(),
        header: 'Usuario'
      }),
      receiptTableColumnHelper.accessor('store', {
        cell: (info) => (
          <Text
            textTransform='uppercase'
            fontWeight={700}
            fontSize={{ base: '0.7rem', lg: '.9rem' }}
          >
            {info.getValue()}
          </Text>
        ),
        header: 'Comercio'
      }),
      receiptTableColumnHelper.accessor('amount', {
        cell: (info) => (
          <Tag
            fontSize={{
              base: '0.6rem',
              lg: '.9rem'
            }}
            colorScheme='blue'
            fontWeight={700}
          >
            {formatCurrencyAmount(info.getValue())}
          </Tag>
        ),
        header: 'Monto'
      }),
      receiptTableColumnHelper.accessor('date', {
        cell: (info) => (
          <Tag
            fontSize={{
              base: '0.6rem',
              lg: '.9rem'
            }}
            fontWeight={700}
          >
            {moment(info.getValue()).format('DD/MM/YYYY')}
          </Tag>
        ),
        header: 'Fecha del Gasto'
      }),

      ...(isLargeScreen ? [] : []),
      receiptTableColumnHelper.accessor('actions', {
        cell: (info) => (
          <Flex
            columnGap='.5rem'
            alignItems='center'
          >
            <Menu>
              <MenuButton
                isDisabled={!info.row.original.status || !info.row.original.receipt_id}
                fontSize='.9rem'
                bg={colors.primary[2]}
                _hover={{
                  bg: colors.tertiary[3]
                }}
                as={Button}
              >
                Descargar&nbsp;
                <Icon as={MdDownload} />
              </MenuButton>
              <MenuList>
                <MenuItem
                  isDisabled={!info.row.original.invoice_pdf_url}
                  onClick={() => {
                    window.open(info.row.original.invoice_pdf_url, '_blank')
                  }}
                  justifyContent='space-between'
                >
                  Descargar PDF <Icon as={MdPictureAsPdf} />
                </MenuItem>
                <MenuItem
                  isDisabled={!info.row.original.invoice_xml_url}
                  onClick={() => {
                    window.open(info.row.original.invoice_xml_url, '_blank')
                  }}
                  justifyContent='space-between'
                >
                  Descargar XML
                  <Icon as={MdFolderZip} />
                </MenuItem>
              </MenuList>
            </Menu>
            <Button
              id={`expenseDetailsButton-${info.row.index}`}
              fontSize='.9rem'
              bg={colors.tertiary[3]}
              aria-label='details button'
              onClick={() => navigate(`${routerObj.receiptDetails.cleanParamsPath}/${info.row.original.expense_id}`)}
              padding='0.4rem'
              cursor='pointer'
            >
              <IoEye />
            </Button>
          </Flex>
        ),
        header: ''
      })
    ],
    [expenses, isLargeScreen]
  )

  return (
    <PageLayout>
      <Stack mb='1rem'>
        <Flex
          flexDir={{
            base: 'column',
            md: 'row'
          }}
          mb='1rem'
          alignItems='center'
          justifyContent={{
            base: '',
            md: 'space-between'
          }}
        >
          <Title>Gastos</Title>
          <DownloadExpensesModal />
        </Flex>
        <ExpensesFilter toggleIsLoading={toggleIsLoading} />
        <DataTable
          isLoading={isLoading}
          data={tableData}
          columns={receiptColumns}
        />
      </Stack>
    </PageLayout>
  )
}

export { ReceiptsDashboardPage }
