import React from 'react'
import { Modal, useDisclosure, Radio, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Heading, RadioGroup, Stack, ModalCloseButton, Flex, useToast } from '@chakra-ui/react'
import { ArrowDownIcon } from '@chakra-ui/icons'
import { DateRangePicker, DateValue } from '../fields/DateRangePicker/DateRangePicker'
import { SelectField } from '../fields'
import { DownloadExpensesType } from '../../ts/interfaces/expenses'
import { ExpensesService } from '../../services/Expenses'
import moment from 'moment'
import { Button } from '../fragments/Button'
import { Text } from '../fragments/Text'
import { colors } from '../../utils/colors'

const DownloadExpensesModal = React.memo(() => {
  const [selectedDates, setSelectedDates] = React.useState<DateValue>([null, null])
  const [exportDataType, setExportDataType] = React.useState<DownloadExpensesType | undefined>()
  const [predefinedDate, setPredefinedDate] = React.useState<string | undefined>('1')
  const modalDisclosure = useDisclosure()
  const loadingDisclosure = useDisclosure()
  const toast = useToast()

  React.useEffect(() => {
    if (!modalDisclosure.isOpen) {
      setPredefinedDate('1')
      setSelectedDates([null, null])
      setExportDataType(undefined)
    }
  }, [modalDisclosure.isOpen])

  const isDisabledDownload = React.useMemo(() => !exportDataType || (predefinedDate === '5' && !selectedDates[0] && !selectedDates[1]), [selectedDates, exportDataType, predefinedDate])

  const handleDownload = React.useCallback(async () => {
    let start_date = moment().startOf('day').toDate()
    let end_date = moment().endOf('day').toDate()

    if (predefinedDate === '5') {
      start_date = selectedDates[0] as Date
      end_date = selectedDates[1] as Date
    } else if (predefinedDate === '4') {
      start_date = moment().subtract(1, 'month').startOf('month').toDate()
      end_date = moment().subtract(1, 'month').endOf('month').toDate()
    } else if (predefinedDate === '3') {
      start_date = moment().startOf('month').toDate()
      end_date = moment().endOf('month').toDate()
    } else if (predefinedDate === '2') {
      start_date = moment().subtract(7, 'days').startOf('day').toDate()
      end_date = moment().endOf('day').toDate()
    }

    try {
      loadingDisclosure.onOpen()
      await ExpensesService({
        useLoader: false,
        handleResponseError: (error) => {
          const status = error.response?.status || error.status || 0
          if ([404].includes(status)) {
            toast({
              title: 'No se encontraron resultados',
              description: 'Por favor, intente con otros criterios de búsqueda.',
              status: 'warning',
              isClosable: true,
              duration: 5000
            })
          }
        }
      }).downloadExpensesData({
        download_type: exportDataType as DownloadExpensesType,
        start_date: start_date,
        end_date: end_date
      })
      loadingDisclosure.onClose()
    } catch (error) {
      loadingDisclosure.onClose()
    }
  }, [exportDataType, selectedDates, predefinedDate])

  return (
    <>
      <Button
        onClick={modalDisclosure.onOpen}
        rightIcon={<ArrowDownIcon />}
        id='DownloadExpensesButton'
      >
        Descargar Gastos
      </Button>

      <Modal
        trapFocus={false}
        closeOnOverlayClick={!loadingDisclosure.isOpen}
        isOpen={modalDisclosure.isOpen}
        onClose={modalDisclosure.onClose}
        isCentered
        size={{
          base: 'full',
          md: 'lg'
        }}
      >
        <ModalOverlay />

        <ModalContent>
          <ModalHeader textAlign='center'>
            <Heading
              textAlign='start'
              fontSize='1.5rem'
              as={'h2'}
              mb='1rem'
            >
              Descargar Gastos
            </Heading>
            <Text
              textAlign='start'
              fontSize='1rem'
              fontWeight={400}
            >
              Toda la información se descargará usando la fecha de los recibos.
            </Text>
            <ModalCloseButton isDisabled={loadingDisclosure.isOpen} />
          </ModalHeader>

          <ModalBody>
            <SelectField
              id='DownloadExpensesTypeSelect'
              customWidth={{
                base: '100%',
                md: '80%'
              }}
              placeholder='Selecciona el tipo de descarga'
              isClearable={false}
              name='exportDataType'
              value={exportDataType}
              onChange={(_, value) => {
                setExportDataType(value as DownloadExpensesType)
              }}
              options={[
                {
                  label: 'Excel',
                  value: DownloadExpensesType.CSV
                },
                {
                  label: 'Zip con PDF y XML',
                  value: DownloadExpensesType.ZIP_PDF_XML
                }
              ]}
            />
            <RadioGroup
              mb='1rem'
              onChange={setPredefinedDate}
              value={predefinedDate}
            >
              <Stack direction='column'>
                <Radio value='1'>Hoy</Radio>
                <Radio value='2'>Últimos siete días</Radio>
                <Radio value='3'>Mes actual</Radio>
                <Radio value='4'>Mes pasado</Radio>
                <Radio value='5'>Personalizado</Radio>
              </Stack>
            </RadioGroup>

            <DateRangePicker
              value={selectedDates}
              onChange={setSelectedDates}
              isDisabled={predefinedDate !== '5'}
            />
          </ModalBody>

          <ModalFooter justifyContent='center'>
            <Flex
              direction='column'
              columnGap='1rem'
              rowGap='1rem'
              width='100%'
            >
              <Flex justifyContent='center'>
                <Button
                  id='DownloadExpensesSubmitButton'
                  onClick={handleDownload}
                  isLoading={loadingDisclosure.isOpen}
                  isDisabled={isDisabledDownload || loadingDisclosure.isOpen}
                >
                  Descargar
                </Button>
              </Flex>

              <Text
                fontSize='.8rem'
                textColor={colors.tertiary[1]}
                fontWeight={600}
                display={loadingDisclosure.isOpen ? 'initial' : 'none'}
              >
                *Por favor espera unos segundos mientras se prepara el archivo, este proceso puede tardar un par de minutos.*
              </Text>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
})

export { DownloadExpensesModal }
