import React from 'react'
import { Box, Flex, FlexProps, Icon, Link, Text } from '@chakra-ui/react'
import { colors } from '../../utils/colors'
import { rounded } from '../../utils/ui'
import { FaWhatsapp } from 'react-icons/fa'
import { useAppSelector } from '../../store/hooks'
import QRCode from 'react-qr-code'

interface ISendInvoiceBanner extends FlexProps {
  isDisabled?: boolean
}
const SendInvoiceBanner = React.memo(({ isDisabled = false, ...props }: ISendInvoiceBanner) => {
  const user = useAppSelector((state) => state.user)

  const url = React.useMemo(() => `whatsapp://send?phone=${process.env.REACT_APP_ZUMMI_WHATSAPP_PHONE_NUMBER}&text=${encodeURIComponent(`¡Hola Zummi! Quiero facturar un ticket 🧾`)}`, [user])

  return (
    <Flex
      id='SendInvoiceBanner'
      alignItems='center'
      flexDir='column'
      {...props}
    >
      <Box
        display={{
          base: 'none',
          md: 'block'
        }}
        mt='1rem'
      >
        <QRCode
          size={150}
          value={url}
        />
      </Box>
      <Link
        rounded={rounded.container}
        px={'.5rem'}
        py={'.5rem'}
        mt={{
          md: '1rem'
        }}
        bg={colors.whatsapp.light}
        as='a'
        href={url}
        textColor={colors.white}
        transition='.2s ease'
        boxShadow='sm'
        _hover={{
          boxShadow: 'xl',
          textDecoration: 'underline'
        }}
      >
        <Text
          fontWeight={600}
          fontSize={{
            base: '1.1rem'
          }}
        >
          Ir a facturar en WhatsApp&nbsp;
          <Icon
            ml='.6rem'
            fontWeight={700}
            fontSize='2rem'
            as={FaWhatsapp}
          />
        </Text>
      </Link>
    </Flex>
  )
})

export { SendInvoiceBanner }
